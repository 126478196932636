import React from "react"
import loadable from '@loadable/component'
// const ResponsiveBar = loadable(() => import('@nivo/bar'), {
//   resolveComponent: (components) => components.ResponsiveBar,
// })
import { ResponsiveBar } from '@nivo/bar'

const Bar = ({data,keys,unit,hoverUnit,label,title,size,window, max, gridY})=>{
  let unitHeight
  if (size === "small"){
    unitHeight = 200
  }
  if (size === "large"){
    unitHeight = 300
  }
  return(
    <div className="bar-chart">
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={"name"}
        maxValue={ max === undefined ? "auto" : max}
        gridYValues={gridY}
        margin={{ top: title === "" ? 25 :50, right: 10, bottom: 25, left: 30 }}
        padding={keys.length === 2? 0.5: 0.65}
        innerPadding={window > 576? 10: 5}
        groupMode="grouped"
        colors={["#97E3D5", "#60CCBA"]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 15,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 2,
        }}
        markers={[
          {
            axis: 'y',
            value: 0,
            textStyle: { fill: '#212529', fontSize: 12},
            legend: unit,
            legendPosition: 'top-left',
            legendOffsetX: -30,
            legendOffsetY:  unitHeight,
          },{
            axis: 'y',
            value: 0,
            textStyle: { fill: '#212529' },
            legend: title,
            legendPosition: 'top',
            legendOffsetX: 0,
            legendOffsetY:  unitHeight+20,
          }
        ]}
        defs={[{
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#39BBB1',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }]}
        fill={[
          {
            match: {
              id: keys[1]
            },
            id: 'lines'
          }
        ]}
        borderRadius={window > 992? 10: 5}
        legends={[
          {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'row',
              justify: false,
              translateX: -5,
              translateY: -20,
              itemsSpacing: 10,
              itemWidth: 20*keys[0].length,
              itemHeight: 10,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 14,
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemOpacity: 1
                      }
                  }
              ]
          }
        ]}
        enableLabel={label}
        tooltipFormat={value => value+hoverUnit}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  )
}

export default Bar